var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "project-list-component table-component" },
    [
      _c("div", { staticClass: "page-header" }, [
        _c(
          "div",
          { staticClass: "page-breadcrumb" },
          [
            _c(
              "a-breadcrumb",
              [
                _c(
                  "a-breadcrumb-item",
                  [_c("router-link", { attrs: { to: "/" } }, [_vm._v("首页")])],
                  1
                ),
                _c("a-breadcrumb-item", [_vm._v("项目管理")])
              ],
              1
            )
          ],
          1
        )
      ]),
      _c(
        "div",
        { staticClass: "page-body page-body-margin" },
        [
          _c(
            "a-card",
            { staticClass: "table-query-card", attrs: { title: "查询" } },
            [
              _c("jtl-table-query-component", {
                ref: _vm.JTL.CONSTANT.DEFAULT_TABLE_QUERY_NAME
              })
            ],
            1
          ),
          _c(
            "a-card",
            { attrs: { title: "列表" } },
            [
              _c(
                "jtl-button",
                {
                  staticClass: "table-header-button",
                  attrs: { slot: "extra", type: "primary" },
                  on: {
                    click: function($event) {
                      _vm.addClick(new _vm.ProjectEntityModel())
                    }
                  },
                  slot: "extra"
                },
                [_vm._v("+ 添加")]
              ),
              _c("a-table", {
                attrs: {
                  loading: _vm.listLoading,
                  columns: _vm.tableColumns,
                  "data-source": _vm.listData,
                  pagination: _vm.Pagination,
                  scroll: _vm.tableScroll,
                  "row-key": _vm.TableRowKey,
                  size: _vm.TableSize
                },
                on: { change: _vm.tableChange },
                scopedSlots: _vm._u([
                  {
                    key: "name",
                    fn: function(text, record) {
                      return [
                        _c(
                          "a",
                          {
                            staticClass: "jtl-link",
                            on: {
                              click: function($event) {
                                return _vm.selectProjectClick(record)
                              }
                            }
                          },
                          [_vm._v(_vm._s(text))]
                        )
                      ]
                    }
                  },
                  {
                    key: "action",
                    fn: function(text, record) {
                      return [
                        _c(
                          "a",
                          {
                            staticClass: "jtl-edit-link",
                            on: {
                              click: function($event) {
                                return _vm.editClick(record, true)
                              }
                            }
                          },
                          [_vm._v("编辑")]
                        ),
                        _c("a-divider", { attrs: { type: "vertical" } }),
                        _c(
                          "a-popconfirm",
                          {
                            attrs: {
                              title: "确认删除?",
                              "ok-text": "确认",
                              "cancel-text": "取消"
                            },
                            on: {
                              confirm: function($event) {
                                return _vm.deleteClick(record)
                              }
                            }
                          },
                          [
                            _c("a", { staticClass: "jtl-del-link" }, [
                              _vm._v("删除")
                            ])
                          ]
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      ),
      _c("jtl-entity-dialog", {
        ref: "formDialog",
        on: { dialogOK: _vm.formDialogOK }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }