











































import { Component } from 'vue-property-decorator';
import TableDialogFormComponent from '@/mixins/table-dialog-form-component';
import { ProjectEntityModel, ProjectQueryModel } from '@/entity-model/project-entity';
import ProjectService from '@/service/project';
import { UserStoreModule } from '@/store/modules/user';
import CommonService from '@/service/common';

@Component
export default class ProjectListComponent extends TableDialogFormComponent<ProjectEntityModel, ProjectQueryModel> {
    ProjectEntityModel = ProjectEntityModel;
    created() {
        this.initTable({
            service: ProjectService,
            queryModel: new ProjectQueryModel(),
            tableColumns: ProjectEntityModel.getTableColumns()
        });
        this.getList();
    }

    async selectProjectClick(project: ProjectEntityModel) {
        UserStoreModule.SET_CURRENT_PROJECT(project);
        await CommonService.getUserPermissions2(0);
        this.$router.push('/dashboard/index');
    }
}

